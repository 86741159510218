import React from "react"
import ReactAudioPlayer from 'react-audio-player';
import { useStaticQuery, graphql } from "gatsby"

export default function AudioComponent() {
  const data = useStaticQuery(graphql`
    {
      contentfulResourcePage {
        audioSection {
          fileUpload {
            file {
              url
            }
            title
          }
        }
      }
    }
  `)
  const audio = data.contentfulResourcePage.audioSection.fileUpload;
  const list = audio.slice(-6).reverse();
  return (
    <div className="video">
      <div className="tab-content">
        <div className="row g-3">
          {list.map(({ file, title }, index) => (
            <div className="video-block col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
              <div className="card text-white shadow-sm">
                <div className="card-body card-content">
                  <h4 className="card-title-header">{title}</h4>
                  <ReactAudioPlayer
                    src={file.url}
                    controls
                    className="audioStyle"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
