import React from "react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { useStaticQuery, graphql } from "gatsby"


export default function FlyerComponent() {
  const data = useStaticQuery(graphql`
    {
      contentfulResourcePage {
        imagesSection {
          fileUpload {
            file {
              url
            }
            title
          }
        }
      }
    }
  `)
  const imgContent = data.contentfulResourcePage.imagesSection.fileUpload;
  const list = imgContent.slice(-6).reverse();
  const style = {
    width: '350px',
    height: '250px',
    objectFit: 'cover'
  }
  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <div className="video">
          <div className="tab-content">
            <div className="row g-3">
              {list.map(({ file, title }, index) => (
                <div className="video-block col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                  <img className="card-img-top" src={file.url} alt={title} style={style} />
                </div>
              ))}
            </div>
          </div>
        </div >
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};
