import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function VideoComponent() {
  const data = useStaticQuery(graphql`
    {
      contentfulResourcePage {
        videoSection {
          videoUpload {
            title
            videoLink
          }
        }
      }
    }
  `)

  const videoContent = data.contentfulResourcePage.videoSection.videoUpload;
  const list = videoContent.slice(-6).reverse();
  return (
    <div className="video">
      <div className="tab-content">
        <div className="row g-3">
          {list.map(({ videoLink, title }, index) => (
            <div className="video-block col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
              <div className="card  text-white shadow-sm">
                <iframe className="card-img-top"
                  height="200pt"
                  src={videoLink}
                  title={title}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen />
                <div className="card-body card-content">
                  <h4 className="card-title-header">{title}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};

