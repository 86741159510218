import * as React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Resource from '../components/resource/resource';

const ProjectResources = () => (
  <Layout>
    <SEO title="Resources" />
    <div className="sidebar-page-container">
      <div className="services-section">
        <div className="auto-container">
          <div className="sec-title">
            <div className="title">tekki fii</div>
            <h2>Project <span>Resources</span></h2>
          </div>
          <div className="container">
            <Resource />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ProjectResources
