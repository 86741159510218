import React from 'react';
import { Link } from 'gatsby';
import VideoComponent from '../video/video';
import AudioComponent from '../audio/audio';
import FlyerComponent from '../flyer/flyer';
import DocumentComponent from '../document/document';
import { useStaticQuery, graphql } from "gatsby"

export default function Resource() {
  const data = useStaticQuery(graphql`
    {
      contentfulResourcePage {
        videoSection {
          name
          title
          seeMoreLink
        }
        audioSection {
          name
          title
          seeMoreLink
        }
        documentSection {
          title
          name
          seeMoreLink
        }
        imagesSection {
          title
          name
          seeMoreLink
        }
      }
    }
  `)
  const videoDetail = data.contentfulResourcePage.videoSection;
  const audioDetail = data.contentfulResourcePage.audioSection;
  const docDetail = data.contentfulResourcePage.documentSection;
  const imgDetail = data.contentfulResourcePage.imagesSection;

  return (
    <React.Fragment>
      <div className="resource-section">
        <div className="video-section">
          <div className="view-more d-flex justify-content-between align-items-center">
            <h5>{videoDetail.name}</h5>
            <Link to={videoDetail.seeMoreLink}>
              <span className="txt">See More</span>
            </Link>
          </div>
          <VideoComponent />
        </div>
        <div className="video-section">
          <div className="view-more d-flex justify-content-between align-items-center">
            <h5>{audioDetail.name}</h5>
            <Link to={audioDetail.seeMoreLink}>
              <span className="txt">See More</span>
            </Link>
          </div>
          <AudioComponent />
        </div>
        <div className="video-section">
          <div className="view-more d-flex justify-content-between align-items-center">
            <h5>{docDetail.name}</h5>
            <Link to={docDetail.seeMoreLink}>
              <span className="txt">See More</span>
            </Link>
          </div>
          <DocumentComponent />
        </div>
        <div className="video-section">
          <div className="view-more d-flex justify-content-between align-items-center">
            <h5>{imgDetail.name}</h5>
            <Link to={imgDetail.seeMoreLink}>
              <span className="txt">See More</span>
            </Link>
          </div>
          < FlyerComponent />
        </div>
      </div>
    </React.Fragment>
  );
};
